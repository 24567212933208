import {
    createApp
} from 'vue';
import { createPinia } from 'pinia'
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import Datepicker from '@vuepic/vue-datepicker';
import Toast from "vue-toastification";
import axios from 'axios';
import "vue-toastification/dist/index.css";

import "@/assets/css/bootstrap.min.css";


/**
 * Core CSS
 */
import "@/assets/css/core.css";
import "@/assets/css/style.css";
import "mdi-icons/css/materialdesignicons.min.css";
import '../node_modules/nprogress/nprogress.css';

/**
 * Core JS
 */
import '@/assets/js/jquery/app.js';
import "@/assets/js/bootstrap.bundle.min.js";
import "@/assets/js/detect.js";
import "@/assets/js/fastclick.js";
import "@/assets/js/jquery.blockUI.js";
import "@/assets/js/waves.js";
import "@/assets/js/jquery.slimscroll.js";
import "@/assets/js/jquery.scrollTo.min.js";
import "@/assets/js/jquery.app.js";

/**
 * Plugins JS
 */
import "datatables.net"
import 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'datatables.net-responsive'
import 'datatables.net-responsive-bs4'
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.css'
import '@vuepic/vue-datepicker/dist/main.css'

axios.defaults.baseURL = (process.env.NODE_ENV == 'production') 
    ? process.env.VUE_APP_APIPRODURI 
    : process.env.VUE_APP_APIDEVURI

const pinia = createPinia()
const app = createApp(App);
app.component('Datepicker', Datepicker);
app.use(pinia)
app.use(router);
app.use(Toast, {
    timeout: 5000
});
app.mount('#app');