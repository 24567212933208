import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import NProgress from 'nprogress';

const version = process.env.VUE_APP_VERSION;

const routes = [{
		title: 'Login Page',
		path: `/${version}/sign-in`,
		name: 'signIn',
		component: () => import('../components/auth/Login.vue')
	},
	{
		path: '',
		component: () => import('../components/Main.vue'),
		redirect: {
			name: 'Home'
		},
		children: [{
				path: '/',
				name: 'Home',
				component: () => import('../components/Home.vue')
			},
			{
				path: `/${version}/dasar`,
				name: 'dataDasar',
				redirect: `/${version}/dasar/dinkes`,
				component: () => import('../components/dasar/Main.vue'),
				children: [{
						path: `/${version}/dasar/dinkes`,
						name: 'dinkes',
						component: () => import('../components/dasar/DinkesComponent.vue'),
					},
					{
						path: `/${version}/dasar/puskesmas`,
						name: 'puskesmas',
						component: () => import('../components/dasar/PuskesmasComponent.vue')
					},
					{
						path: `/${version}/dasar/backup`,
						name: 'backup',
						component: () => import('../components/dasar/BackupDataComponent.vue')
					},
					{
						path: `/${version}dasar/restore`,
						name: 'restore',
						component: () => import('../components/dasar/RestoreComponent.vue')
					}
				]
			},
			{
				path: `/${version}`,
				component: () => import('../components/bumil/Main.vue'),
				children: [
					{
						path: `bumil`,
						redirect:{
							name:'bumil'
						},
						children: [
							{
								path: ``,
								name: 'bumil',
								component: () => import('../components/bumil/hamil/Home.vue'),
							},
							{
								path: `register`,
								name: 'bumilRegister',
								component: () => import('../components/bumil/hamil/Register.vue'),
							}
						]
					},
					{
						path: `/${version}/bumil/risti`,
						name: 'bumilRisti',
						component: () => import('../components/bumil/risti/Home.vue'),
					},
					{
						path: `/${version}/bumil/melahirkan`,
						name: 'bumilMelahirkan',
						component: () => import('../components/bumil/melahirkan/Home.vue'),
					},
				]
			},
			{
				path: `/${version}/balita`,
				name: 'balita',
				component: () => import('../components/balita/Home.vue')
			},
			{
				path: `/${version}/rekap`,
				name: `rekap`,
				redirect: `/${version}/rekap/bumil`,
				component: () => import('../components/rekap/Main.vue'),
				children: [{
						path: `/${version}/rekap/bumil`,
						name: 'rekap_bumil',
						component: () => import('../components/rekap/Bumil.vue'),
					},
					{
						path: `/${version}/rekap/balita`,
						name: 'rekap_balita',
						component: () => import('../components/rekap/Balita.vue'),
					},
					{
						path: `/${version}/rekap/imunisasi`,
						name: 'rekap_imunisasi',
						component: () => import('../components/rekap/Imunisasi.vue'),
					},
					{
						path: `/${version}/rekap/gizi`,
						name: 'rekap_gizi',
						component: () => import('../components/rekap/Gizi.vue'),
					},
				]
			},
			{
				path: `/${version}/grafik`,
				component: () => import('../components/grafik/Main.vue'),
				name: 'grafik',
				redirect: {
					name: 'grafik_bumil'
				},
				children: [{
						path: `bumil`,
						name: 'grafik_bumil',
						component: () => import('../components/grafik/Bumil.vue'),
					},
					{
						path: `balita`,
						name: 'grafik_balita',
						component: () => import('../components/grafik/Balita.vue'),
					},
					{
						path: `imunisasi`,
						name: 'grafik_imunisasi',
						component: () => import('../components/grafik/Imunisasi.vue'),
					},
				]
			},
			{
				path: `/${version}/imunisasi`,
				name: 'imunisasi',
				component: () => import('../components/imunisasi/Home.vue')
			},
			{
				path: `/${version}/peta/temuan`,
				name: 'petaTemuan',
				component: () => import('../components/peta/Temuan.vue')
			},
			{
				path: `/${version}/peta/bumil`,
				name: 'petaBumil',
				component: () => import('../components/peta/Bumil.vue')
			},
			{
				path: `/${version}/peta/bayi`,
				name: 'petaBayi',
				component: () => import('../components/peta/Bayi.vue')
			},
			{
				path: `/${version}/periode`,
				name: 'periode',
				component: () => import('../components/periode/Home.vue')
			},
		]
	},
	{
		path: '/:catchAll(.*)',
		name: 'NotFound',
		component: () => import('../components/errors/NotFound.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeResolve((to, from, next) => {
	if (to.name) {
		NProgress.start()
		NProgress.set(0.1)
		NProgress.configure({
			ease: 'ease',
			speed: 500
		})
	}
	next()
})

router.afterEach(() => {
	NProgress.done()
})

export default router