(function ($) {

    'use strict';

    function initNavbar() {

        $(document).on('click', '#open-mega', function () {
            console.log('init');
            $(this).toggleClass('open');
            $('#navigation').slideToggle(400);
        });
    }

    function initSlimscroll() {
        $('.slimscroll-noti').slimScroll({
            height: '230px',
            position: 'right',
            size: "5px",
            color: '#98a6ad',
            wheelStep: 10
        });
    }

    function init() {
        initNavbar();
        initSlimscroll();
    }

    init();

})(jQuery);