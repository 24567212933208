/* eslint-disable no-console */

import {
	register
} from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
	register(`service-worker.js`, {
		ready() {},
		registered() {},
		cached() {},
		updatefound() {},
		updated() {
			window.location.reload(true);
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.')
		},
		error(error) {
			console.error(error)
		}
	})
}